import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import IniBanner from "../components/initial"
import Servicos from "../components/services"
import Solutions from "../components/solutions"
import Sobre from "../components/sobre"

const IndexPage = () => (
  <Layout>
    <SEO title="Hermes soluções em automação industrial" />
    <IniBanner></IniBanner>
    <Sobre></Sobre>
    <Servicos></Servicos>
    <Solutions></Solutions>
    <a class="btn btn-dark rounded-pill" style={{position:'fixed',bottom:'20px',right:'20px',margin:'0',padding:'0px 3px', textDecoration:'none'}} href="#">
      <div style={{fontSize: '3vh', fontWeight: '300', lineHeight: '1.2'}}>
        <span class="ti-angle-up"></span>
      </div>
    </a>

  </Layout>
)

export default IndexPage
