import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import Logo from "../components/logo"

const IniBanner = () => (
  <div id="bg-inicial" class="jumbotron ui-hero ui-mh-100vh ui-bg-cover ui-bg-overlay-container text-white h-auto" style={{borderRadius:'0px', minHeight: '100vh'}}>
    <div class="ui-bg-overlay bg-dark opacity-75"></div>
    <div class="container flex-shrink-1 my-5">
      <div class="row">

        <div class="col-md-8 col-lg-8 col-xl-8 text-md-left text-center">
          <Logo></Logo>
          <h1 class="tx-60 text-white tx-semibold">
            Hermes Soluções em Automação Industrial
          </h1>
          <p class="lead pb-2 my-4">
          Soluções para suas necessidades, atendendo com eficiência, determinação, segurança e aptidão, sempre observando as melhores práticas de desenvolvimento do mercado.

          </p>
          <a href="https://api.whatsapp.com/send?phone=5549936182719" target="_blank" class="btn btn-primary">Entrar em contato</a>  
          <a href="#sobre" class="btn btn-light ml-2">Sobre nós</a>
        </div>

      </div>
    </div>
  </div>
)

export default IniBanner