import React from "react"


const Servicos =()=> (
  <div id="servicos" class="jumbotron ui-mh-100vh ui-bg-cover ui-bg-overlay-container">
    <div class="ui-bg-overlay bg-light op-7"></div>
    <h1 class="text-center mg-t-50 pt-lg-5">Serviços</h1>
    <div class="container flex-shrink-1 my-5">
      <div class="row text-center">
        <div class="col-sm-6 col-lg-4 my-4">
          <div class="display-4  mx-auto mb-4">
            <span class="ti-desktop"></span>
          </div>
          <h5>Automação</h5>
          <div class="ui-company-text  tx-gray-500">
            Desenvolvemos soluções em automação conforme as necessidades da sua empresa, 
            superando suas expectativas e atendendo às suas demandas. 
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 my-4">
          <div class="display-4  mx-auto mb-4">
            <span class="ti-stats-up"></span>
          </div>
          <h5>Informação</h5>
          <div class="ui-company-text  tx-gray-500">
            Com foco na indústria 4.0 gostaríamos de analisar, em conjunto, 
            as novas possibilidades de geração de valor na sua produção.
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 my-4">
          <div class="display-4  mx-auto mb-4">
            <span class="ti-medall"></span>
          </div>
          <h5>Treinamento</h5>
          <div class="ui-company-text  tx-gray-500">
            Para evitar baixas de produção trabalhamos em conjunto para treinar a sua equipe, 
            evitando perdas e maximizando o funcionamento da sua empresa.
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 my-4">
          <div class="display-4  mx-auto mb-4">
            <span class="ti-headphone-alt"></span>
          </div>
          <h5>Suporte</h5>
          <div class="ui-company-text  tx-gray-500">
            Com profissionais especializados, prestamos auxílio tanto remota quanto presencialmente. 
            Manter sua fábrica em produção é importante para nós.
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 my-4">
          <div class="display-4  mx-auto mb-4">
            <span class="ti-timer"></span>
          </div>
          <h5>Eficiência</h5>
          <div class="ui-company-text  tx-gray-500">
            Comprometimento com você, estamos ao seu lado para que as 
            soluções superem as expectativas e fortaleçam a sua competitividade.
          </div>
        </div>
        <div class="col-sm-6 col-lg-4 my-4">
          <div class="display-4  mx-auto mb-4">
            <span class="ti-link"></span>
          </div>
          <h5>Parcerias</h5>
          <div class="ui-company-text  tx-gray-500">
            Contando com inúmeras parcerias buscamos estreitar laços, 
            trabalhamos com ética para entregar as melhores soluções.
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Servicos