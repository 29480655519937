import React from "react"


const Sobre = () => (  
  <div id="sobre" class="jumbotron ui-mh-100vh ui-bg-cover ui-bg-overlay-container">
    <div class="ui-bg-overlay bg-write op-7"></div>
    <h1 class="text-center mg-t-50 pt-lg-5 mb-5">Sobre nós</h1>
    <div class="lead col-md-10 col-lg-8 col-xl-7 text-center  tx-gray-500 p-0 mx-auto mb-4">
      Iniciamos nossos trabalhos em 2018. Desde então, formamos diversas parcerias e desenvolvemos soluções para as mais diversas demandas.
    </div>
    <div class="lead col-md-10 col-lg-8 col-xl-7 text-center  tx-gray-500 p-0 mx-auto mb-4">
      Nossos focos são o cliente e a qualidade de nossos serviços, 
      e o nosso objetivo é ser uma referência como uma das maiores empresas de software na região oeste de Santa Catarina.
    </div>
    <div class="lead col-md-10 col-lg-8 col-xl-7 text-center  tx-gray-500 p-0 mx-auto mb-4">
      Buscamos o aprimoramento contínuo sempre observando nossos pilares: <br/>
      PESSOAS, PRODUTOS e GERAÇÃO DE VALOR.
    </div>
  </div>
)

export default Sobre