import React from "react"


const Solutions = () => (  
  <div id="solucoes" class="jumbotron ui-mh-100vh ui-bg-cover ui-bg-overlay-container">
    <div class="ui-bg-overlay bg-write op-7"></div>
    <h1 class="text-center mg-t-50 pt-lg-5">Soluções</h1>
    <div class="lead col-md-10 col-lg-8 col-xl-7 text-center  tx-gray-500 p-0 mx-auto mb-4">
      Através de soluções altamente customizáveis e com foco nas necessidades do seu projeto, 
      desenvolvemos estratégias para otimizar seu processo e melhorar as tomadas de decisão.
    </div>
    <div class="container pt-lg-5 flex-shrink-1 my-5">
      <div class="row text-center">
        <div class="col-sm-6 col-lg-4">
          <div class="mx-auto">
            <div class="img-header" id="sol-food-beverage"></div>
          </div>
          <h5 class="tx-semibold">Alimentos e bebidas</h5>
          <div class="ui-company-text  tx-gray-500 mb-4">
            Com foco na segurança e na eficiência que são marcas do setor de alimentos.
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="mx-auto">
            <div class="img-header" id="sol-papper"></div>
          </div>
          <h5 class="tx-semibold">Papel e Celulose</h5>
          <div class="ui-company-text  tx-gray-500 mb-4">
            Qualidade de desenvolvimento e suporte qualificado para minimizar os downtimes do seu processo.
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="mx-auto">
            <div class="img-header" id="sol-eta-ete"></div>
          </div>
          <h5 class="tx-semibold">Saneamento</h5>
          <div class="ui-company-text  tx-gray-500 mb-4">
            Prevenção de erros operacionais e eficácia na utilização dos insumos de tratamento.
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="mx-auto">
            <div class="img-header" id="sol-heating"></div>
          </div>
          <h5 class="tx-semibold">Caldeira e sistemas têrmicos</h5>
          <div class="ui-company-text  tx-gray-500 mb-4">
            Constante busca pela precisão para maior estabilidade da produção.
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="mx-auto">
            <div class="img-header" id="sol-cooling"></div>
          </div>
          <h5 class="tx-semibold">Refrigeração e controle de ambientes</h5>
          <div class="ui-company-text  tx-gray-500 mb-4">
            Informações decisivas para tomada de decisão e facilidade operacional.          
          </div>
        </div>
        <div class="col-sm-6 col-lg-4">
          <div class="mx-auto">
            <div class="img-header" id="sol-machine"></div>
          </div>
          <h5 class="tx-semibold">Máquinas</h5>
          <div class="ui-company-text  tx-gray-500 mb-4">
            Desenvolvimento customizado para maior adaptabilidade e performance.
          </div>
        </div>
      </div>
    </div>
  </div>
)

export default Solutions